"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var SignInModal_1 = require("../SignIn/SignInModal");
var Button_1 = require("office-ui-fabric-react/lib/Button");
var firebase = __importStar(require("firebase/app"));
require("firebase/auth");
var signInClass = '';
var authenticatedUserClass = '';
var NavBar = (function (_super) {
    __extends(NavBar, _super);
    function NavBar(props) {
        return _super.call(this, props) || this;
    }
    NavBar.prototype.signOut = function () {
        firebase.auth().signOut();
    };
    NavBar.prototype.render = function () {
        if (this.props.userState) {
            signInClass = 'hide-sign-in-button';
            authenticatedUserClass = 'authenticated-user';
        }
        else {
            signInClass = 'sign-in-button';
            authenticatedUserClass = 'hide-authenticated-user';
        }
        return (React.createElement("div", { className: 'ms-Grid-row nav-row' },
            React.createElement("div", { className: 'arup-logo ms-Grid-col' },
                React.createElement("img", { src: './ArupLogo.jpg' })),
            React.createElement("div", { className: "" + signInClass },
                React.createElement(SignInModal_1.SignInModal, { type: 'signIn' })),
            React.createElement("div", { className: "" + authenticatedUserClass },
                React.createElement("span", { className: 'signed-in-user' }, this.props.userState ? this.props.userState.email : ''),
                React.createElement(Button_1.DefaultButton, { className: 'sign-out-button', secondaryText: "Signs user out of Firebase", onClick: this.signOut, text: 'Sign out' }))));
    };
    return NavBar;
}(React.Component));
exports.NavBar = NavBar;
