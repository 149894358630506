"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var Spinner = (function (_super) {
    __extends(Spinner, _super);
    function Spinner(props) {
        return _super.call(this, props) || this;
    }
    Spinner.prototype.render = function () {
        return (React.createElement("div", { className: 'spinner' },
            React.createElement("div", { className: "sk-fading-circle" },
                React.createElement("div", { className: "sk-circle1 sk-circle" }),
                React.createElement("div", { className: "sk-circle2 sk-circle" }),
                React.createElement("div", { className: "sk-circle3 sk-circle" }),
                React.createElement("div", { className: "sk-circle4 sk-circle" }),
                React.createElement("div", { className: "sk-circle5 sk-circle" }),
                React.createElement("div", { className: "sk-circle6 sk-circle" }),
                React.createElement("div", { className: "sk-circle7 sk-circle" }),
                React.createElement("div", { className: "sk-circle8 sk-circle" }),
                React.createElement("div", { className: "sk-circle9 sk-circle" }),
                React.createElement("div", { className: "sk-circle10 sk-circle" }),
                React.createElement("div", { className: "sk-circle11 sk-circle" }),
                React.createElement("div", { className: "sk-circle12 sk-circle" }))));
    };
    return Spinner;
}(React.Component));
exports.Spinner = Spinner;
