"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var deleteCollectionData_1 = require("../../../api/deleteCollectionData");
var ContactModal_1 = require("../ContactModal");
var ManageContactsItem = (function (_super) {
    __extends(ManageContactsItem, _super);
    function ManageContactsItem(props) {
        var _this = _super.call(this, props) || this;
        _this.handleDelete = _this.handleDelete.bind(_this);
        _this.deleteContact = _this.deleteContact.bind(_this);
        return _this;
    }
    ManageContactsItem.prototype.handleDelete = function () {
        var ok = confirm("Are you sure you want to delete this contact? " + this.props.contact.name);
        if (ok == true) {
            this.deleteContact();
        }
    };
    ManageContactsItem.prototype.deleteContact = function () {
        var _this = this;
        var api = new deleteCollectionData_1.DeleteCollectionDataApi();
        api.deleteCollection('contacts', this.props.contact.id).then(function () {
            _this.props.refreshData();
        });
        event.preventDefault();
    };
    ManageContactsItem.prototype.render = function () {
        return (React.createElement("div", { className: 'manage-contact-item' },
            React.createElement("span", { className: 'manage-contact-item-title' }, this.props.contact.name),
            React.createElement("span", null,
                "  |  Modified by ",
                React.createElement("b", null, this.props.contact.modifiedBy),
                "  |  "),
            React.createElement(ContactModal_1.ContactModal, { refreshData: this.props.refreshData, contact: this.props.contact, editMode: 'edit', userState: this.props.userState }),
            React.createElement("i", { title: 'Delete this contact', className: "ms-Icon ms-Icon--Delete", "aria-hidden": "true", onClick: this.handleDelete })));
    };
    return ManageContactsItem;
}(React.Component));
exports.ManageContactsItem = ManageContactsItem;
