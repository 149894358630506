"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var getCollectionData_1 = require("../../../api/getCollectionData");
var ManageUsersModal_1 = require("./ManageUsersModal");
var ManageUsersContainer = (function (_super) {
    __extends(ManageUsersContainer, _super);
    function ManageUsersContainer(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            users: []
        };
        _this.updateUsersState = _this.updateUsersState.bind(_this);
        return _this;
    }
    ManageUsersContainer.prototype.componentDidMount = function () {
        var _this = this;
        var api = new getCollectionData_1.GetCollectionDataApi();
        api.getCollectionOrdered('users', 'email').then(function (item) {
            _this.setState({ users: item });
        });
    };
    ManageUsersContainer.prototype.updateUsersState = function () {
        var _this = this;
        var api = new getCollectionData_1.GetCollectionDataApi();
        api.getCollectionOrdered('users', 'email').then(function (item) {
            _this.setState({ users: item });
        });
    };
    ManageUsersContainer.prototype.render = function () {
        return (React.createElement(ManageUsersModal_1.ManageUsersModal, { users: this.state.users, refreshData: this.updateUsersState, userState: this.props.userState, refreshDataMainPage: this.props.refreshDataMainPage }));
    };
    return ManageUsersContainer;
}(React.Component));
exports.ManageUsersContainer = ManageUsersContainer;
