"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var Modal_1 = require("office-ui-fabric-react/lib/Modal");
var Button_1 = require("office-ui-fabric-react/lib/Button");
var ManagePostsList_1 = require("./ManagePostsList");
var ManagePostsModal = (function (_super) {
    __extends(ManagePostsModal, _super);
    function ManagePostsModal(props) {
        var _this = _super.call(this, props) || this;
        _this._showModal = function () {
            _this.setState({ showModal: true });
        };
        _this._closeModal = function (event) {
            event.preventDefault();
            _this.setState({ showModal: false });
            _this.props.refreshData();
            _this.props.refreshDataMainPage();
        };
        _this.state = {
            showModal: false
        };
        return _this;
    }
    ManagePostsModal.prototype.render = function () {
        return (React.createElement("div", null,
            React.createElement(Button_1.DefaultButton, { secondaryText: "Opens modal window", onClick: this._showModal, text: "Manage posts" }),
            React.createElement(Modal_1.Modal, { titleAriaId: "titleId", subtitleAriaId: "subtitleId", isOpen: this.state.showModal, onDismiss: this._closeModal, isBlocking: false, containerClassName: "modal" },
                React.createElement("div", { className: 'close-button' },
                    React.createElement(Button_1.DefaultButton, { onClick: this._closeModal, text: "Close" })),
                React.createElement(ManagePostsList_1.ManagePostsList, { posts: this.props.posts, refreshData: this.props.refreshData, userState: this.props.userState }))));
    };
    return ManagePostsModal;
}(React.Component));
exports.ManagePostsModal = ManagePostsModal;
