"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var ViewUser_1 = require("./ViewUser");
var UserModal_1 = require("./UserModal");
var ManageUsersContainer_1 = require("./ManageUsers/ManageUsersContainer");
var hideModal = '';
var DisplayUsersList = (function (_super) {
    __extends(DisplayUsersList, _super);
    function DisplayUsersList(props) {
        return _super.call(this, props) || this;
    }
    DisplayUsersList.prototype.render = function () {
        var _this = this;
        if (this.props.userPermission !== 'administrator') {
            hideModal = 'hide-modal';
        }
        else {
            hideModal = '';
        }
        var getUsers = this.props.users.map(function (item) {
            return React.createElement(ViewUser_1.ViewUser, { user: item, userPermission: _this.props.userPermission });
        });
        return (React.createElement("div", { className: "users-list " + hideModal },
            React.createElement("div", { className: 'users-title-row' },
                React.createElement("div", { className: 'users-title' },
                    React.createElement("span", null, "Users")),
                React.createElement("div", { className: "users-create-button" },
                    React.createElement(UserModal_1.UserModal, { refreshData: this.props.refreshData, editMode: 'create', userState: this.props.userState })),
                React.createElement("div", { className: "users-create-button" },
                    React.createElement(ManageUsersContainer_1.ManageUsersContainer, { userState: this.props.userState, refreshDataMainPage: this.props.refreshData }))),
            React.createElement("div", { className: 'users-filler-row' }),
            getUsers));
    };
    return DisplayUsersList;
}(React.Component));
exports.DisplayUsersList = DisplayUsersList;
