"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var setCollectionData_1 = require("../../../api/setCollectionData");
var TextField_1 = require("office-ui-fabric-react/lib/TextField");
var Button_1 = require("office-ui-fabric-react/lib/Button");
var EditContact = (function (_super) {
    __extends(EditContact, _super);
    function EditContact(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { name: _this.props.contact.name, phone: _this.props.contact.phone, email: _this.props.contact.email, id: _this.props.contact.id, createdDate: _this.props.contact.createdDate, createdBy: _this.props.contact.createdBy, modifiedDate: _this.props.contact.modifiedDate, modifiedBy: _this.props.contact.modifiedBy };
        _this.handleNameChange = _this.handleNameChange.bind(_this);
        _this.handlePhoneChange = _this.handlePhoneChange.bind(_this);
        _this.handleEmailChange = _this.handleEmailChange.bind(_this);
        _this.handleSubmit = _this.handleSubmit.bind(_this);
        return _this;
    }
    EditContact.prototype.handleNameChange = function (event) {
        this.setState({ name: event.target.value });
    };
    EditContact.prototype.handlePhoneChange = function (event) {
        this.setState({ phone: event.target.value });
    };
    EditContact.prototype.handleEmailChange = function (event) {
        this.setState({ email: event.target.value });
    };
    EditContact.prototype.handleSubmit = function (event) {
        var _this = this;
        var api = new setCollectionData_1.SetCollectionDataApi();
        api.editContactDocument(this.state).then(function () {
            _this.props.closeModal();
        });
        event.preventDefault();
    };
    EditContact.prototype.render = function () {
        return (React.createElement("form", { className: 'modal-form-contact', onSubmit: this.handleSubmit },
            React.createElement("h2", null, "Edit contact"),
            React.createElement("div", null,
                React.createElement(TextField_1.TextField, { label: "Name ", required: true, value: this.state.name, onChange: this.handleNameChange })),
            React.createElement("div", null,
                React.createElement(TextField_1.TextField, { label: "Phone ", value: this.state.phone, onChange: this.handlePhoneChange })),
            React.createElement("div", null,
                React.createElement(TextField_1.TextField, { label: "Email ", value: this.state.email, onChange: this.handleEmailChange })),
            React.createElement("p", null),
            React.createElement("div", null,
                React.createElement(Button_1.DefaultButton, { secondaryText: "Adds this contact", type: "submit", text: "Submit" })),
            React.createElement("p", null),
            React.createElement("div", null,
                "Created: ",
                this.state.createdDate.toUTCString()),
            React.createElement("div", null,
                "Created by: ",
                this.state.createdBy),
            React.createElement("div", null,
                "Last modified: ",
                this.state.modifiedDate.toUTCString()),
            React.createElement("div", null,
                "Last modified by: ",
                this.state.createdBy)));
    };
    return EditContact;
}(React.Component));
exports.EditContact = EditContact;
