"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var hostName = window.location.hostname;
var getEnvVars = function () {
    var envObj;
    switch (hostName) {
        case 'arup-emergency-info.firebaseapp.com':
        case 'arupemergency.info':
            envObj = { apiKey: 'AIzaSyCjMxgfagsId0rZMGRTtsvHKB8A5dTvuXw', authDomain: 'arup-emergency-info.firebaseapp.com', projectId: 'arup-emergency-info', env: 'prod' };
            break;
        case 'arup-emergency-info-dev.firebaseapp.com':
            envObj = { apiKey: 'AIzaSyDkdYc8u65lBI0gGvVx7kRNmvnGxrYwfdc', authDomain: 'arup-emergency-info-dev.firebaseapp.com', projectId: 'arup-emergency-info-dev', env: 'dev' };
            break;
        case 'arup-emergency-info-test.firebaseapp.com':
            envObj = { apiKey: 'AIzaSyC6OgNPsQ4Jbx4GkLxZdDb12yuL4hPjcOs', authDomain: 'arup-emergency-info-test.firebaseapp.com', projectId: 'arup-emergency-info-test', env: 'test' };
            break;
        case 'arup-emergency-info-uat1.firebaseapp.com':
            envObj = { apiKey: 'AIzaSyDjThTMyGGwJwXJXQ3FhZqVCIkDOVCNxwE', authDomain: 'arup-emergency-info-uat1.firebaseapp.com', projectId: 'arup-emergency-info-uat1', env: 'uat' };
            break;
    }
    return envObj;
};
exports.getEnvVars = getEnvVars;
