"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var setCollectionData_1 = require("../../../api/setCollectionData");
var TextField_1 = require("office-ui-fabric-react/lib/TextField");
var Button_1 = require("office-ui-fabric-react/lib/Button");
var CheckBox_1 = require("../../Reuseable/CheckBox/CheckBox");
var EditPost = (function (_super) {
    __extends(EditPost, _super);
    function EditPost(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { title: _this.props.post.title, body: _this.props.post.body, isCurrent: _this.props.post.isCurrent, id: _this.props.post.id, createdDate: _this.props.post.createdDate, createdBy: _this.props.post.createdBy, modifiedDate: _this.props.post.modifiedDate, modifiedBy: _this.props.post.modifiedBy };
        _this.handleTitleChange = _this.handleTitleChange.bind(_this);
        _this.handleBodyChange = _this.handleBodyChange.bind(_this);
        _this.handleIsCurrentChange = _this.handleIsCurrentChange.bind(_this);
        _this.handleSubmit = _this.handleSubmit.bind(_this);
        return _this;
    }
    EditPost.prototype.handleTitleChange = function (event) {
        this.setState({ title: event.target.value });
    };
    EditPost.prototype.handleBodyChange = function (event) {
        this.setState({ body: event.target.value });
    };
    EditPost.prototype.handleIsCurrentChange = function (checkedState) {
        this.setState({ isCurrent: checkedState });
    };
    EditPost.prototype.handleSubmit = function (event) {
        var _this = this;
        var api = new setCollectionData_1.SetCollectionDataApi();
        api.editPostDocument(this.state).then(function () {
            _this.props.closeModal();
        });
        event.preventDefault();
    };
    EditPost.prototype.render = function () {
        return (React.createElement("form", { className: 'modal-form', onSubmit: this.handleSubmit },
            React.createElement("h2", null, "Edit post"),
            React.createElement("div", null,
                React.createElement(TextField_1.TextField, { label: "Title ", required: true, value: this.state.title, onChange: this.handleTitleChange })),
            React.createElement("div", null,
                React.createElement(TextField_1.TextField, { label: "Body", multiline: true, rows: 10, required: true, value: this.state.body, onChange: this.handleBodyChange })),
            React.createElement("p", null),
            React.createElement(CheckBox_1.CheckBox, { isChecked: this.state.isCurrent, changeCheckedState: this.handleIsCurrentChange, checkBoxLabel: 'Display on homepage' }),
            React.createElement("p", null),
            React.createElement("div", null,
                React.createElement(Button_1.DefaultButton, { secondaryText: "Submits edits to this post", type: "submit", text: "Submit" })),
            React.createElement("p", null),
            React.createElement("div", null,
                "Created: ",
                this.state.createdDate.toUTCString()),
            React.createElement("div", null,
                "Created by: ",
                this.state.createdBy),
            React.createElement("div", null,
                "Last modified: ",
                this.state.modifiedDate.toUTCString()),
            React.createElement("div", null,
                "Last modified by: ",
                this.state.createdBy)));
    };
    return EditPost;
}(React.Component));
exports.EditPost = EditPost;
