"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var PostModal_1 = require("./PostModal");
var ManagePostsContainer_1 = require("./ManagePosts/ManagePostsContainer");
var NoPosts_1 = require("./NoPosts");
var hideButton = '';
var DisplayPostsList = (function (_super) {
    __extends(DisplayPostsList, _super);
    function DisplayPostsList(props) {
        return _super.call(this, props) || this;
    }
    DisplayPostsList.prototype.render = function () {
        var _this = this;
        if (!this.props.userPermission) {
            hideButton = 'hide-modal';
        }
        else {
            hideButton = '';
        }
        var getPosts = function () {
            if (_this.props.posts.length > 0) {
                return _this.props.posts.map(function (item) {
                    return React.createElement(PostModal_1.PostModal, { post: item, editMode: 'view', userState: _this.props.userState });
                });
            }
            else {
                return React.createElement(NoPosts_1.NoPosts, null);
            }
        };
        return (React.createElement("div", { className: 'post-list' },
            React.createElement("div", { className: 'posts-title-row' },
                React.createElement("div", { className: 'posts-title' },
                    React.createElement("span", null, "Current posts")),
                React.createElement("div", { className: "posts-create-button " + hideButton },
                    React.createElement(PostModal_1.PostModal, { refreshData: this.props.refreshData, editMode: 'create', userState: this.props.userState })),
                React.createElement("div", { className: "posts-create-button " + hideButton },
                    React.createElement(ManagePostsContainer_1.ManagePostsContainer, { userState: this.props.userState, refreshDataMainPage: this.props.refreshData }))),
            React.createElement("div", { className: 'posts-filler-row' }),
            getPosts()));
    };
    return DisplayPostsList;
}(React.Component));
exports.DisplayPostsList = DisplayPostsList;
