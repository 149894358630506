"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var Modal_1 = require("office-ui-fabric-react/lib/Modal");
var Button_1 = require("office-ui-fabric-react/lib/Button");
var CreateUser_1 = require("./CreateUser");
var EditUser_1 = require("./ManageUsers/EditUser");
var UserModal = (function (_super) {
    __extends(UserModal, _super);
    function UserModal(props) {
        var _this = _super.call(this, props) || this;
        _this._showModal = function () {
            _this.setState({ showModal: true });
        };
        _this._closeModal = function () {
            _this.setState({ showModal: false });
            _this.props.refreshData();
        };
        _this.state = {
            showModal: false
        };
        return _this;
    }
    UserModal.prototype.render = function () {
        var _this = this;
        var renderLink = function (editMode, user) {
            switch (editMode) {
                case 'create':
                    return React.createElement(Button_1.DefaultButton, { secondaryText: "Opens modal window", onClick: _this._showModal, text: "Create user" });
                case 'edit':
                    return React.createElement("i", { title: 'Edit this user', className: "ms-Icon ms-Icon--Edit", "aria-hidden": "true", onClick: _this._showModal });
            }
        };
        var renderUserForm = function (editMode) {
            switch (editMode) {
                case 'create':
                    return React.createElement(CreateUser_1.CreateUser, { refreshData: _this.props.refreshData, closeModal: _this._closeModal, userState: _this.props.userState });
                case 'edit':
                    return React.createElement(EditUser_1.EditUser, { user: _this.props.user, refreshData: _this.props.refreshData, closeModal: _this._closeModal });
            }
        };
        return (React.createElement("div", { className: 'modal-link' },
            renderLink(this.props.editMode, this.props.user),
            React.createElement(Modal_1.Modal, { titleAriaId: "titleId", subtitleAriaId: "subtitleId", isOpen: this.state.showModal, onDismiss: this._closeModal, isBlocking: false, containerClassName: "post-modal" },
                React.createElement("div", { className: 'close-button' },
                    React.createElement(Button_1.DefaultButton, { onClick: this._closeModal, text: "Close" })),
                renderUserForm(this.props.editMode))));
    };
    return UserModal;
}(React.Component));
exports.UserModal = UserModal;
