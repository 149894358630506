"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var setCollectionData_1 = require("../../../api/setCollectionData");
var TextField_1 = require("office-ui-fabric-react/lib/TextField");
var Button_1 = require("office-ui-fabric-react/lib/Button");
var ChoiceRadioButtons_1 = require("../../Reuseable/ChoiceRadioButtons/ChoiceRadioButtons");
var EditUser = (function (_super) {
    __extends(EditUser, _super);
    function EditUser(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { email: _this.props.user.email, admin: _this.props.user.admin, id: _this.props.user.id, createdDate: _this.props.user.createdDate, createdBy: _this.props.user.createdBy, modifiedDate: _this.props.user.modifiedDate, modifiedBy: _this.props.user.modifiedBy };
        _this.handleIdChange = _this.handleIdChange.bind(_this);
        _this.handleEmailChange = _this.handleEmailChange.bind(_this);
        _this.handlePermissionLevelChange = _this.handlePermissionLevelChange.bind(_this);
        _this.handleSubmit = _this.handleSubmit.bind(_this);
        return _this;
    }
    EditUser.prototype.handleIdChange = function (event) {
        this.setState({ id: event.target.value });
    };
    EditUser.prototype.handleEmailChange = function (event) {
        this.setState({ email: event.target.value });
    };
    EditUser.prototype.handlePermissionLevelChange = function (optionState) {
        this.setState({ permissionLevel: optionState.key });
    };
    EditUser.prototype.handleSubmit = function (event) {
        var _this = this;
        var api = new setCollectionData_1.SetCollectionDataApi();
        api.editUserDocument(this.state).then(function () {
            _this.props.closeModal();
        });
        event.preventDefault();
    };
    EditUser.prototype.render = function () {
        var choiceOptions = [
            {
                key: 'editor',
                text: 'Editor'
            },
            {
                key: 'administrator',
                text: 'Administrator'
            }
        ];
        return (React.createElement("form", { className: 'modal-form-user', onSubmit: this.handleSubmit },
            React.createElement("h2", null, "Edit user"),
            React.createElement("div", null,
                React.createElement(TextField_1.TextField, { label: "Id", required: true, value: this.state.id, onChange: this.handleIdChange })),
            React.createElement("div", null,
                React.createElement(TextField_1.TextField, { label: "Email", required: true, value: this.state.email, onChange: this.handleEmailChange })),
            React.createElement(ChoiceRadioButtons_1.ChoiceRadioButtons, { choiceOptions: choiceOptions, defaultKey: 'editor', label: 'Select permission level', changeOption: this.handlePermissionLevelChange }),
            React.createElement("p", null),
            React.createElement(Button_1.DefaultButton, { secondaryText: "Edits this user", type: "submit", text: "Submit" }),
            React.createElement("p", null),
            React.createElement("div", null,
                "Created: ",
                this.state.createdDate.toUTCString()),
            React.createElement("div", null,
                "Created by: ",
                this.state.createdBy),
            React.createElement("div", null,
                "Last modified: ",
                this.state.modifiedDate.toUTCString()),
            React.createElement("div", null,
                "Last modified by: ",
                this.state.createdBy)));
    };
    return EditUser;
}(React.Component));
exports.EditUser = EditUser;
