"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var ViewContact = (function (_super) {
    __extends(ViewContact, _super);
    function ViewContact(props) {
        return _super.call(this, props) || this;
    }
    ViewContact.prototype.render = function () {
        var _this = this;
        var hideShowPhone = function () {
            return !_this.props.contact.phone ? 'hide-element' : '';
        };
        var hideShowEmail = function () {
            return !_this.props.contact.email ? 'hide-element' : '';
        };
        return (React.createElement("div", { className: 'contact-item' },
            React.createElement("div", { className: 'contact-name' }, this.props.contact.name),
            React.createElement("div", { className: "contact-phone " + hideShowPhone() },
                React.createElement("i", { className: "ms-Icon ms-Icon--Phone", "aria-hidden": "true" }),
                React.createElement("a", { href: "tel:" + this.props.contact.phone }, this.props.contact.phone)),
            React.createElement("div", { className: "contact-email " + hideShowEmail() },
                React.createElement("i", { className: "ms-Icon ms-Icon--Mail", "aria-hidden": "true" }),
                React.createElement("a", { href: "mailto:" + this.props.contact.email }, this.props.contact.email))));
    };
    return ViewContact;
}(React.Component));
exports.ViewContact = ViewContact;
