"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var ManageContactsItem_1 = require("./ManageContactsItem");
var ManageContactsList = (function (_super) {
    __extends(ManageContactsList, _super);
    function ManageContactsList(props) {
        return _super.call(this, props) || this;
    }
    ManageContactsList.prototype.render = function () {
        var _this = this;
        var getContacts = this.props.contacts.map(function (item) {
            return React.createElement(ManageContactsItem_1.ManageContactsItem, { contact: item, refreshData: _this.props.refreshData, userState: _this.props.userState });
        });
        return (React.createElement("div", null,
            React.createElement("span", { className: 'modal-title' }, "Manage contacts"),
            getContacts));
    };
    return ManageContactsList;
}(React.Component));
exports.ManageContactsList = ManageContactsList;
