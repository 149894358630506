"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var connectFirebase_1 = require("./connectFirebase");
var AddCollectionDataApi = (function () {
    function AddCollectionDataApi() {
    }
    AddCollectionDataApi.prototype.addPost = function (post) {
        return connectFirebase_1.db.collection('posts').add({
            title: post.title,
            body: post.body,
            isCurrent: post.isCurrent,
            createdDate: connectFirebase_1.timeStamp,
            modifiedDate: connectFirebase_1.timeStamp,
            createdBy: post.createdBy,
            modifiedBy: post.createdBy
        })
            .catch(function (error) {
            console.error("Error adding document: ", error);
        });
    };
    AddCollectionDataApi.prototype.addUser = function (user) {
        return connectFirebase_1.db.collection('users').doc(user.id).set({
            id: user.id,
            email: user.email,
            admin: user.admin,
            permissionLevel: user.permissionLevel,
            createdDate: connectFirebase_1.timeStamp,
            modifiedDate: connectFirebase_1.timeStamp,
            createdBy: user.createdBy,
            modifiedBy: user.createdBy
        })
            .catch(function (error) {
            console.error("Error adding document: ", error);
        });
    };
    AddCollectionDataApi.prototype.addContact = function (contact) {
        return connectFirebase_1.db.collection('contacts').add({
            name: contact.name,
            phone: contact.phone,
            email: contact.email,
            createdDate: connectFirebase_1.timeStamp,
            modifiedDate: connectFirebase_1.timeStamp,
            createdBy: contact.createdBy,
            modifiedBy: contact.createdBy
        })
            .catch(function (error) {
            console.error("Error adding document: ", error);
        });
    };
    return AddCollectionDataApi;
}());
exports.AddCollectionDataApi = AddCollectionDataApi;
