"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var connectFirebase_1 = require("./connectFirebase");
var GetCollectionDataApi = (function () {
    function GetCollectionDataApi() {
        this._getDate = function (dataObj) {
            return dataObj.createdDate.toDate();
        };
    }
    GetCollectionDataApi.prototype.getCollection = function (collection) {
        var _this = this;
        var documents = connectFirebase_1.db.collection(collection);
        var arr1 = [];
        return documents.get()
            .then(function (snapshot) {
            snapshot.forEach(function (doc) {
                var obj = doc.data();
                obj.id = doc.id;
                if (obj.createdDate) {
                    var dateObject = _this._getDate(obj);
                    obj.createdDate = dateObject;
                }
                arr1.push(obj);
            });
            return arr1;
        });
    };
    GetCollectionDataApi.prototype.getCollectionOrdered = function (collection, field, desc) {
        var documents = connectFirebase_1.db.collection(collection);
        var arr1 = [];
        return documents.orderBy(field, desc).get()
            .then(function (snapshot) {
            snapshot.forEach(function (doc) {
                var obj = doc.data();
                obj.id = doc.id;
                if (obj.createdDate) {
                    var dateObject = obj.createdDate.toDate();
                    obj.createdDate = dateObject;
                }
                if (obj.modifiedDate) {
                    var dateObject2 = obj.modifiedDate.toDate();
                    obj.modifiedDate = dateObject2;
                }
                arr1.push(obj);
            });
            return arr1;
        });
    };
    GetCollectionDataApi.prototype.getDocument = function (post, collection) {
        var docRef = connectFirebase_1.db.collection(collection).doc(post.id);
        return docRef.get().then(function (doc) {
            if (doc.exists) {
            }
            else {
                console.log("No such document!");
            }
        }).catch(function (error) {
            console.log("Error getting document:", error);
        });
    };
    GetCollectionDataApi.prototype.getUserDocument = function (userId, collection) {
        var docRef = connectFirebase_1.db.collection(collection).doc(userId);
        return docRef.get().then(function (doc) {
            if (doc.exists) {
                return doc.data();
            }
            else {
                console.log("No such document!");
            }
        }).catch(function (error) {
            console.log("Error getting document:", error);
        });
    };
    return GetCollectionDataApi;
}());
exports.GetCollectionDataApi = GetCollectionDataApi;
