"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var getCollectionData_1 = require("../api/getCollectionData");
var getEnvVars_1 = require("../helpers/getEnvVars");
var NavBar_1 = require("./NavBar/NavBar");
var DisplayPostsList_1 = require("./Posts/DisplayPostsList");
var DisplayContactsList_1 = require("./Contacts/DisplayContactsList");
var DisplayUsersList_1 = require("./Users/DisplayUsersList");
var icons_1 = require("@uifabric/icons");
var Spinner_1 = require("./Reuseable/Spinner/Spinner");
var firebase = __importStar(require("firebase/app"));
require("firebase/auth");
icons_1.initializeIcons();
var App = (function (_super) {
    __extends(App, _super);
    function App(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            contacts: [],
            posts: [],
            users: [],
            user: null,
            userPermission: '',
            postsRetrieved: false,
            contactsRetrieved: false
        };
        _this.updatePostsState = _this.updatePostsState.bind(_this);
        _this.updateContactsState = _this.updateContactsState.bind(_this);
        _this.updateUsersState = _this.updateUsersState.bind(_this);
        return _this;
    }
    App.prototype.componentDidMount = function () {
        this.updateContactsState();
        this.updatePostsState();
        this.checkUserAuth();
    };
    App.prototype.checkUserAuth = function () {
        var _this = this;
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                _this.getUserPermissions(user.uid);
            }
            else {
                _this.setState({ userPermission: '' });
            }
            _this.setState({
                user: user,
            });
        });
    };
    App.prototype.getUserPermissions = function (userId) {
        var _this = this;
        var api = new getCollectionData_1.GetCollectionDataApi();
        api.getUserDocument(userId, 'users').then(function (user) {
            if (user) {
                if (user.permissionLevel == 'administrator') {
                    _this.setState({ userPermission: user.permissionLevel });
                    _this.updateUsersState();
                }
                else {
                    _this.setState({ userPermission: user.permissionLevel });
                    _this.updateUsersState();
                }
            }
            else {
                console.log('this is noOne');
            }
        });
    };
    App.prototype.updatePostsState = function () {
        var _this = this;
        this.setState({ postsRetrieved: false });
        var api = new getCollectionData_1.GetCollectionDataApi();
        api.getCollectionOrdered('posts', 'createdDate', 'desc').then(function (posts) {
            var currentPosts = [];
            posts.map(function (item) {
                item.isCurrent && currentPosts.push(item);
            });
            _this.setState({ posts: currentPosts, postsRetrieved: true });
        });
    };
    App.prototype.updateContactsState = function () {
        var _this = this;
        this.setState({ contactsRetrieved: false });
        var api = new getCollectionData_1.GetCollectionDataApi();
        api.getCollectionOrdered('contacts', 'name').then(function (item) {
            _this.setState({ contacts: item, contactsRetrieved: true });
        });
    };
    App.prototype.updateUsersState = function () {
        var _this = this;
        var api = new getCollectionData_1.GetCollectionDataApi();
        api.getCollectionOrdered('users', 'email').then(function (item) {
            _this.setState({ users: item });
        });
    };
    App.prototype.render = function () {
        var _this = this;
        var contactsSpinner = function () {
            if (!_this.state.contactsRetrieved) {
                return React.createElement(Spinner_1.Spinner, null);
            }
            else {
                return React.createElement(DisplayContactsList_1.DisplayContactsList, { contacts: _this.state.contacts, refreshData: _this.updateContactsState, userState: _this.state.user, userPermission: _this.state.userPermission });
            }
        };
        var postsSpinner = function () {
            if (!_this.state.postsRetrieved) {
                return React.createElement(Spinner_1.Spinner, null);
            }
            else {
                return React.createElement(DisplayPostsList_1.DisplayPostsList, { posts: _this.state.posts, refreshData: _this.updatePostsState, userState: _this.state.user, userPermission: _this.state.userPermission });
            }
        };
        return (React.createElement("div", { className: 'ms-Grid', dir: 'ltr' },
            React.createElement(NavBar_1.NavBar, { userState: this.state.user }),
            React.createElement("div", { className: 'ms-Grid-row title-row' },
                React.createElement("div", { className: 'ms-Grid-col nav-col' },
                    React.createElement("h1", null,
                        "Emergency Information ",
                        React.createElement("span", { className: 'title-environment' }, getEnvVars_1.getEnvVars().env !== 'prod' ? getEnvVars_1.getEnvVars().env : '')))),
            React.createElement("div", { className: 'ms-Grid-row' },
                React.createElement("div", { className: 'ms-Grid-col ms-sm12 ms-xl6' },
                    postsSpinner(),
                    React.createElement(DisplayUsersList_1.DisplayUsersList, { users: this.state.users, refreshData: this.updateUsersState, userState: this.state.user, userPermission: this.state.userPermission })),
                React.createElement("div", { className: 'ms-Grid-col ms-sm12 ms-xl5 ms-xlPush1' },
                    React.createElement("div", null, contactsSpinner()))),
            React.createElement("div", null)));
    };
    return App;
}(React.Component));
exports.default = App;
