"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var TextField_1 = require("office-ui-fabric-react/lib/TextField");
var Button_1 = require("office-ui-fabric-react/lib/Button");
var firebase = __importStar(require("firebase/app"));
require("firebase/auth");
var ResetPassword = (function (_super) {
    __extends(ResetPassword, _super);
    function ResetPassword(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { email: '', password: '' };
        _this.handlePasswordChange = _this.handlePasswordChange.bind(_this);
        _this.handleEmailChange = _this.handleEmailChange.bind(_this);
        _this.handleSubmit = _this.handleSubmit.bind(_this);
        return _this;
    }
    ResetPassword.prototype.handlePasswordChange = function (event) {
        this.setState({ password: event.target.value });
    };
    ResetPassword.prototype.handleEmailChange = function (event) {
        this.setState({ email: event.target.value });
    };
    ResetPassword.prototype.handleSubmit = function (event) {
        firebase.auth().sendPasswordResetEmail(this.state.email).then(function (error) {
            var errorCode = error.code;
            var errorMessage = error.message;
        });
        this.props.closeModal();
        event.preventDefault();
    };
    ResetPassword.prototype.render = function () {
        return (React.createElement("div", { className: 'sign-in-container' },
            React.createElement("h2", null, "Reset password"),
            React.createElement("p", null, "Enter the email addressed used for your Arup Emergency Informantion admin account and click the \"Reset password\" button."),
            React.createElement("p", null, "An email will be sent to you with a link to set a new password for your account."),
            React.createElement("form", { onSubmit: this.handleSubmit },
                React.createElement(TextField_1.TextField, { label: "Email", required: true, value: this.state.email, onChange: this.handleEmailChange }),
                React.createElement("p", null),
                React.createElement("p", null),
                React.createElement(Button_1.DefaultButton, { secondaryText: "Reset password", type: "submit", text: "Reset password" }))));
    };
    return ResetPassword;
}(React.Component));
exports.ResetPassword = ResetPassword;
