"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var connectFirebase_1 = require("./connectFirebase");
var DeleteCollectionDataApi = (function () {
    function DeleteCollectionDataApi() {
    }
    DeleteCollectionDataApi.prototype.deleteCollection = function (collection, id) {
        return connectFirebase_1.db.collection(collection).doc(id).delete().then(function () {
        }).catch(function (error) {
            console.error("Error removing document: ", error);
        });
    };
    return DeleteCollectionDataApi;
}());
exports.DeleteCollectionDataApi = DeleteCollectionDataApi;
