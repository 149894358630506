"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var ManageUsersItem_1 = require("./ManageUsersItem");
var ManageUsersList = (function (_super) {
    __extends(ManageUsersList, _super);
    function ManageUsersList(props) {
        return _super.call(this, props) || this;
    }
    ManageUsersList.prototype.render = function () {
        var _this = this;
        var getUsers = this.props.users.map(function (item) {
            return React.createElement(ManageUsersItem_1.ManageUsersItem, { user: item, refreshData: _this.props.refreshData, userState: _this.props.userState });
        });
        return (React.createElement("div", null,
            React.createElement("span", { className: 'modal-title' }, "Manage users"),
            getUsers));
    };
    return ManageUsersList;
}(React.Component));
exports.ManageUsersList = ManageUsersList;
