"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var connectFirebase_1 = require("./connectFirebase");
var SetCollectionDataApi = (function () {
    function SetCollectionDataApi() {
    }
    SetCollectionDataApi.prototype.editPostDocument = function (post) {
        return connectFirebase_1.db.collection('posts').doc(post.id).set({
            title: post.title,
            body: post.body,
            isCurrent: post.isCurrent,
            createdDate: post.createdDate,
            createdBy: post.createdBy,
            modifiedDate: connectFirebase_1.timeStamp,
            modifiedBy: post.modifiedBy
        })
            .then(function () {
            console.log("Document successfully written!");
        })
            .catch(function (error) {
            console.error("Error writing document: ", error);
        });
    };
    SetCollectionDataApi.prototype.editContactDocument = function (contact) {
        return connectFirebase_1.db.collection('contacts').doc(contact.id).set({
            name: contact.name,
            phone: contact.phone,
            email: contact.email,
            createdDate: contact.createdDate,
            createdBy: contact.createdBy,
            modifiedDate: connectFirebase_1.timeStamp,
            modifiedBy: contact.modifiedBy
        })
            .then(function () {
            console.log("Document successfully written!");
        })
            .catch(function (error) {
            console.error("Error writing document: ", error);
        });
    };
    SetCollectionDataApi.prototype.editUserDocument = function (user) {
        return connectFirebase_1.db.collection('users').doc(user.id).set({
            id: user.id,
            email: user.email,
            permissionLevel: user.permissionLevel,
            createdDate: user.createdDate,
            createdBy: user.createdBy,
            modifiedDate: connectFirebase_1.timeStamp,
            modifiedBy: user.modifiedBy
        })
            .then(function () {
            console.log("Document successfully written!");
        })
            .catch(function (error) {
            console.error("Error writing document: ", error);
        });
    };
    return SetCollectionDataApi;
}());
exports.SetCollectionDataApi = SetCollectionDataApi;
