"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var Checkbox_1 = require("office-ui-fabric-react/lib/Checkbox");
var CheckBox = (function (_super) {
    __extends(CheckBox, _super);
    function CheckBox(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { isChecked: false };
        _this._onCheckboxChange = _this._onCheckboxChange.bind(_this);
        return _this;
    }
    CheckBox.prototype.render = function () {
        return (React.createElement("div", null,
            React.createElement(Checkbox_1.Checkbox, { label: this.props.checkBoxLabel, onChange: this._onCheckboxChange, defaultChecked: this.props.isChecked })));
    };
    CheckBox.prototype._onCheckboxChange = function (ev, isChecked) {
        this.props.changeCheckedState(isChecked);
    };
    return CheckBox;
}(React.Component));
exports.CheckBox = CheckBox;
