"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var firebase = __importStar(require("firebase/app"));
require("firebase/firestore");
var getEnvVars_1 = require("../helpers/getEnvVars");
var app = firebase.initializeApp({
    apiKey: getEnvVars_1.getEnvVars().apiKey,
    authDomain: getEnvVars_1.getEnvVars().authDomain,
    projectId: getEnvVars_1.getEnvVars().projectId
});
exports.db = app.firestore();
exports.timeStamp = firebase.firestore.FieldValue.serverTimestamp();
exports.db.settings({
    timestampsInSnapshots: true,
});
