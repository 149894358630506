"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var ViewContact_1 = require("./ViewContact");
var ContactModal_1 = require("./ContactModal");
var ManageContactsContainer_1 = require("./ManageContacts/ManageContactsContainer");
var hideButton = '';
var DisplayContactsList = (function (_super) {
    __extends(DisplayContactsList, _super);
    function DisplayContactsList(props) {
        return _super.call(this, props) || this;
    }
    DisplayContactsList.prototype.render = function () {
        if (!this.props.userPermission) {
            hideButton = 'hide-modal';
        }
        else {
            hideButton = '';
        }
        var getContacts = this.props.contacts.map(function (item) {
            return React.createElement(ViewContact_1.ViewContact, { contact: item });
        });
        return (React.createElement("div", { className: 'contacts-list' },
            React.createElement("div", { className: 'contacts-title-row' },
                React.createElement("div", { className: 'contacts-title' },
                    React.createElement("span", null, "Key contacts")),
                React.createElement("div", { className: "contacts-create-button " + hideButton },
                    React.createElement(ContactModal_1.ContactModal, { refreshData: this.props.refreshData, editMode: 'create', userState: this.props.userState })),
                React.createElement("div", { className: "contacts-create-button " + hideButton },
                    React.createElement(ManageContactsContainer_1.ManageContactsContainer, { userState: this.props.userState, refreshDataMainPage: this.props.refreshData }))),
            React.createElement("div", { className: 'contacts-filler-row' }),
            getContacts));
    };
    return DisplayContactsList;
}(React.Component));
exports.DisplayContactsList = DisplayContactsList;
