"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var addCollectionData_1 = require("../../api/addCollectionData");
var TextField_1 = require("office-ui-fabric-react/lib/TextField");
var Button_1 = require("office-ui-fabric-react/lib/Button");
var CheckBox_1 = require("../Reuseable/CheckBox/CheckBox");
var CreatePost = (function (_super) {
    __extends(CreatePost, _super);
    function CreatePost(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { title: '', body: '', isCurrent: true, createdBy: _this.props.userState.email };
        _this.handleTitleChange = _this.handleTitleChange.bind(_this);
        _this.handleBodyChange = _this.handleBodyChange.bind(_this);
        _this.handleIsCurrentChange = _this.handleIsCurrentChange.bind(_this);
        _this.handleSubmit = _this.handleSubmit.bind(_this);
        return _this;
    }
    CreatePost.prototype.handleTitleChange = function (event) {
        this.setState({ title: event.target.value });
    };
    CreatePost.prototype.handleBodyChange = function (event) {
        this.setState({ body: event.target.value });
    };
    CreatePost.prototype.handleIsCurrentChange = function (checkedState) {
        this.setState({ isCurrent: checkedState });
    };
    CreatePost.prototype.handleSubmit = function (event) {
        var _this = this;
        event.preventDefault();
        var api = new addCollectionData_1.AddCollectionDataApi();
        api.addPost(this.state).then(function () {
            _this.setState({ title: '', body: '' });
            _this.props.closeModal();
        });
    };
    CreatePost.prototype.render = function () {
        return (React.createElement("form", { className: 'modal-form', onSubmit: this.handleSubmit },
            React.createElement("h2", null, "Create post"),
            React.createElement("div", null,
                React.createElement(TextField_1.TextField, { label: "Title ", required: true, value: this.state.title, onChange: this.handleTitleChange })),
            React.createElement("div", null,
                React.createElement(TextField_1.TextField, { label: "Body", autoAdjustHeight: true, multiline: true, rows: 10, required: true, value: this.state.body, onChange: this.handleBodyChange })),
            React.createElement("p", null),
            React.createElement(CheckBox_1.CheckBox, { isChecked: true, changeCheckedState: this.handleIsCurrentChange, checkBoxLabel: 'Display on homepage' }),
            React.createElement("p", null),
            React.createElement("div", null,
                React.createElement(Button_1.DefaultButton, { secondaryText: "Adds this post", type: "submit", text: "Submit" })),
            React.createElement("p", null)));
    };
    return CreatePost;
}(React.Component));
exports.CreatePost = CreatePost;
