"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var Modal_1 = require("office-ui-fabric-react/lib/Modal");
var Button_1 = require("office-ui-fabric-react/lib/Button");
var ResetPassword_1 = require("./ResetPassword");
var SignIn_1 = require("./SignIn");
var SignInModal = (function (_super) {
    __extends(SignInModal, _super);
    function SignInModal(props) {
        var _this = _super.call(this, props) || this;
        _this._showModal = function () {
            _this.setState({ showModal: true });
        };
        _this._closeModal = function () {
            _this.setState({ showModal: false });
        };
        _this.state = {
            showModal: false
        };
        return _this;
    }
    SignInModal.prototype.render = function () {
        var _this = this;
        var buttonLink = function () {
            if (_this.props.type === 'reset-password') {
                return React.createElement("p", null,
                    "Click this link to ",
                    React.createElement("a", { onClick: _this._showModal }, "reset your password"));
            }
            else {
                return React.createElement(Button_1.DefaultButton, { className: 'sign-in-button', secondaryText: "Opens sign in modal", onClick: _this._showModal, text: 'Admin sign in' });
            }
        };
        return (React.createElement("div", null,
            buttonLink(),
            React.createElement(Modal_1.Modal, { titleAriaId: "titleId", subtitleAriaId: "subtitleId", isOpen: this.state.showModal, onDismiss: this._closeModal, isBlocking: false, containerClassName: "post-modal" },
                React.createElement("div", { className: 'close-button' },
                    React.createElement(Button_1.DefaultButton, { onClick: this._closeModal, text: "Close" })),
                this.props.type === 'reset-password' ? React.createElement(ResetPassword_1.ResetPassword, { closeModal: this._closeModal }) : React.createElement(SignIn_1.SignIn, { closeModal: this._closeModal }))));
    };
    return SignInModal;
}(React.Component));
exports.SignInModal = SignInModal;
